/* eslint-disable @typescript-eslint/space-before-function-paren */
import { QueryClient, QueryClientProvider } from "react-query";
import "./app.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { AppContext } from "context/app-context";
import LandingPage from "page/landing";
import AuthComponent from "components/auth-component";
import { LoginCallback } from "@okta/okta-react";
import RouteErrorMessage from "components/route-error";
import RequiredAuthComponent from "components/auth-component/required-auth";
import { PermissionType } from "types/permission-type";
import { createBrowserHistory } from "history";
import MatomoContextProvider from "utils/matomo-context-provider";
import { DEV_DIMENSIONS } from "utils/dimensions";
import CCT from "page/cct";
import { ComingSoon } from "@gitlab-rtsensing/component-library";
import DesktopSideNav from "components/resuable/side-nav";
import PageLayout from "components/resuable/layout";
const initialState = {
  navRoot: null,
};

const matomoClientID = process.env.REACT_APP_MATOMO_CLIENT_ID;
const matomoClientUrl = process.env.REACT_APP_MATOMO_URL;

const history = createBrowserHistory();

interface AppProps {
  permissions: PermissionType[];
}

function LandingApp(props: AppProps) {
  const { permissions } = props;
  const [appState, setAppState] = useState<any>(initialState);
  const queryClient = new QueryClient();
  const [navList, setNavList] = useState({
    label: "DCT HOME",
    authorized: true,
    jumpToSections: [],
    externalSections: [],
    internalSections: []
  });
  return (
    <BrowserRouter>
      <MatomoContextProvider
        url={matomoClientUrl}
        siteId={matomoClientID}
        history={history}
        dimensions={DEV_DIMENSIONS}
      >
        <AppContext.Provider value={{ appState, setAppState, initialState }}>
          <QueryClientProvider client={queryClient}>
            <AuthComponent>
              <div className="site-wrapper">
                <Routes>
                  <Route path="/" element={<RequiredAuthComponent />}>
                    <Route
                      path="/"
                      element={
                        <PageLayout>
                          <div style={{ margin: '120px 48px 16px 48px' }}>
                            <div id="side-nav">
                              <DesktopSideNav hideNav={false} activeRouteData={navList} />
                            </div>
                            <ComingSoon
                              label="Coming Soon"
                            />
                          </div>
                        </PageLayout>
                      }
                    />
                    <Route
                      path="/home"
                      element={
                        <PageLayout>
                          <div style={{ margin: '120px 48px 16px 48px' }}>
                            <div id="side-nav">
                              <DesktopSideNav hideNav={false} activeRouteData={navList} />
                            </div>
                            <ComingSoon
                              label="Coming Soon"
                            />
                          </div>
                        </PageLayout>
                      }
                    />
                    <Route
                      path="/manufacturing"
                      element={<LandingPage permissions={permissions} />}
                    />
                    <Route
                      path="/cct"
                      element={<CCT permissions={permissions} />}
                    />
                  </Route>
                  <Route
                    path="/implicit/callback"
                    element={<LoginCallback />}
                  />
                  <Route
                    path="/unauthorized"
                    element={
                      <RouteErrorMessage routeerror="You are not authorized to access this page" />
                    }
                  />
                </Routes>
              </div>
            </AuthComponent>
          </QueryClientProvider>
        </AppContext.Provider>
      </MatomoContextProvider>
    </BrowserRouter>
  );
}

export default LandingApp;
