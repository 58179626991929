/* eslint-disable react/jsx-no-duplicate-props */
import PageLayout from "components/resuable/layout";
import "./index.scss";
import { Button, MetricCard } from "@gitlab-rtsensing/component-library";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopSideNav from "components/resuable/side-nav";
import { useState, useContext, useEffect } from "react";
import { PermissionType } from "types/permission-type";
import { MatomoContext } from "utils/matomo-context-provider";
import { availableApps, LandingStateInterface } from "utils/constants"
import { Breadcrumb, ConfigProvider, Image } from "antd";
import HeroBanner from "../assets/icons/hero-banner"

interface PageType {
  permissions: PermissionType[];
}

interface permissionObjectType {
  [key: string]: boolean;
}

interface breadcrumbMenuItemType {
  key: string;
  label: JSX.Element;
}

interface breadcrumbItemType {
  title: JSX.Element;
  menu?: { items: breadcrumbMenuItemType[] }
}

const defaultItemType = [{
  title: <a href="/">Digital Control Tower</a>,
}]

const LandingPage = (props: PageType) => {
  const { permissions } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(MatomoContext);
  const [breadcrumbItems, setBreadCrumbItems] = useState<breadcrumbItemType[]>(defaultItemType)

  /***************TODO: Find a better way to do it once we integrate api ***************/
  const permissionObject: permissionObjectType = {
    home: false,
    aoh: false,
    abr: false,
    anc: false,
    ari: false,
    manufacturing: false,
    aml: false,
    adl: false,
    asm: false,
    ato: false,
  };

  useEffect(() => {
    if (location.pathname.includes("manufacturing")) {
      setBreadCrumbItems(prevItems => [
        ...prevItems,
        {
          title: <span>Manufacturing</span>,
          menu: { items: mfgItems }
        }
      ])
    } else {
      setBreadCrumbItems(defaultItemType);
    }
  }, [location.pathname])

  permissions.map((permission: PermissionType) => {
    permissionObject[permission.url] = permission.isAuthorized;
    return permission;
  });

  permissions[1]?.workstreams?.mct?.map((permission: PermissionType) => {
    permissionObject[permission.url] = permission.isAuthorized;
    return permission;
  });

  const availableAppsWithPermission = availableApps.map(
    (app: LandingStateInterface) => {
      return {
        label: app.label,
        route: app.route,
        authorized: permissionObject[app.route],
      };
    }
  );

  // TODO: Replace eTOC permission with actual permission
  const [navList, setNavList] = useState({
    label: "MANUFACTURING",
    authorized: permissionObject.manufacturing,
    jumpToSections: [],
    externalSections: [],
    internalSections: [
      {
        sectionLabel: "MANUFACTURING CONTROL TOWER",
        links: [
          {
            label: "Manufacturing Home",
            url: "/manufacturing",
            authorized: permissionObject.aoh,
            disabled: false,
          },
          {
            label: "Amgen Ohio (AOH)",
            url: "/manufacturing/aoh",
            authorized: permissionObject.aoh,
            disabled: false,
          },
          {
            label: "Amgen Breda (ABR)",
            url: "/manufacturing/abr",
            authorized: permissionObject.abr,
            disabled: false,
          },
          {
            label: "Amgen North Carolina (ANC)",
            url: "/manufacturing/anc",
            authorized: permissionObject.anc,
            disabled: false,
          },
          {
            label: "Amgen Rhode Island (ARI)",
            url: "/manufacturing/ari",
            authorized: permissionObject.ari,
            disabled: false,
          },
          {
            label: "Amgen Puerto Rico (AML)",
            url: "/manufacturing/aml",
            authorized: permissionObject.aml,
            disabled: false,
          },
          {
            label: "Amgen Dun Laoghaire (ADL)",
            url: "/manufacturing/adl",
            authorized: permissionObject.adl,
            disabled: false,
          },
          {
            label: "Amgen Singapore Manufacturing (ASM)",
            url: "/manufacturing/asm",
            authorized: permissionObject.asm,
            disabled: false,
          },
          {
            label: "Amgen Thousand Oaks (ATO)",
            url: "/manufacturing/ato",
            authorized: permissionObject.ato,
            disabled: false,
          }
        ]
      },
      {
        sectionLabel: "Electronic Table of Contents (eTOC)",
        links: [
          {
            label: "Electronic Table of Content (eTOC)",
            // url: "/manufacturing/eTOC",
            authorized: permissionObject.home,
            disabled: true,
          }
        ]
      }
    ]
  });

  const mfgItems = [
    {
      key: '1',
      label: <a href="/manufacturing">Manufacturing</a>
    }
    // ,
    // {
    //   key: '2',
    //   label: <a href="/cct">CCT</a>
    // }
  ]

  return (
    <PageLayout>
      <div className="aoh-pb-3">
        <div className="image-container">
          <HeroBanner />
        </div>
        <ConfigProvider theme={{ token: { fontSize: 12 } }}>
          <Breadcrumb
            className="breadcrumb"
            items={breadcrumbItems}
          />
        </ConfigProvider>
        <div className="d-flex aoh-w-100 aoh-flex-column">
          <div id="side-nav">
            <DesktopSideNav hideNav={false} activeRouteData={navList} />
          </div>
          <div className="sticky-header">
            <div className="ops-fw-semibold landing-header" id="dct-header">
              Manufacturing Control Tower
            </div>
            <div className="ops-fs-1 ops-fw-medium landing-sub-header" id="dct-sub-header">
              The Manufacturing Plant Digital Control Tower will provide an E2E real-time integrated 
              visual platform focused on operational plant level data and metrics to drive faster 
              decision-making, improved data-driven collaboration for daily management, and enhanced 
              ability to identify and operations bottlenecks.
            </div>
          </div>
          <div id="landing-content" className="landing-content">
            {availableAppsWithPermission.map((app, i) => (
              <div key={i}>
                {app.authorized && (
                  <MetricCard key={i}>
                    <div>
                      <MetricCard.Header>
                        <MetricCard.Title title={app.label}></MetricCard.Title>
                      </MetricCard.Header>
                      <MetricCard.Content>
                        <div className="aoh-landing-button-wrapper">
                          <Button
                            className="aoh-view-more"
                            iconPosition="right"
                            onClick={() => {
                              context.trackEvent(
                                "btn_click_event",
                                "Home Page",
                                app.label
                              );
                              context.trackPageView(
                                window.location.origin + "/manufacturing/" + app.route,
                                document.title
                              );
                              navigate(`/manufacturing/${app.route}`, {
                                replace: true,
                              });
                            }}
                            label={`Go to site`}
                            type="primary"
                          />
                        </div>
                      </MetricCard.Content>
                    </div>
                  </MetricCard>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default LandingPage;
