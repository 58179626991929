import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import LandingApp from 'App';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: LandingApp,
  errorBoundary (_err:Error, _info: ErrorInfo, _props: any) {
    return <div>Build Failed while compiling</div>
  }
});

let styleElements: Array<Element>;

export const bootstrap = reactLifecycles.bootstrap;

export const mount = [
  async () => {
    if (styleElements) {
      document.head.append(...styleElements);
    }
  },
  (props: any) => {
    const permission = props?.permissions.filter((item: any) => {
      if (item.url === 'home') {
        return true;
      }
      return false;
    })
    if (permission.length === 0) {
      props.singleSpa.navigateToUrl("/unauthorized");
    } else if (!permission[0].isAuthorized) {
      props.singleSpa.navigateToUrl("/unauthorized");
    }
    return reactLifecycles.mount(props);
  },
  async () => {
    if (!styleElements) {
      styleElements = Array.from(
        document.getElementsByClassName("amgen-dct-landing")
      )
    }
  }
]
export const unmount = [
  reactLifecycles.unmount,
  async () => {
    styleElements.forEach((element) => element.remove());
  }
]
