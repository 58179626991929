/* eslint-disable @typescript-eslint/restrict-plus-operands */
import PageLayout from "components/resuable/layout";
import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "context/app-context";
import { Breadcrumb, ConfigProvider } from "antd";
import { ComingSoon } from "@gitlab-rtsensing/component-library";
import { PermissionType } from "types/permission-type";

interface CCTProps {
  permissions: PermissionType[];
}

const CCT = (props: CCTProps) => {
  const { appState } = useContext(AppContext);
  const { permissions } = props;
  const navigate = useNavigate();

  const mfgItems = [
    {
      key: '1',
      label: <a href="/manufacturing">Manufacturing</a>
    },
    {
      key: '2',
      label: <a href="/cct">CCT</a>
    }
  ]

  return (
    <>
      <PageLayout>
        <div>
        <ConfigProvider theme={{ token: { fontSize: 12 } }}>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <a href="/">Digital Control Tower</a>,
                },
                {
                  title: <span>CCT</span>,
                  menu: { items: mfgItems }
                }
              ]}
            />
          </ConfigProvider>
          <div className="d-flex aoh-w-100 aoh-flex-column">
            {/* <div id="side-nav">
            <DesktopSideNav hideNav={false} activeRouteData={navList} />
          </div> */}
            <div>
              <div className="ops-fs-1 ops-fw-bold landing-header" id="dct-header">
                Clinical Control Tower
              </div>
              {/* <div className="ops-fs-3 ops-fw-bold landing-sub-header" id="dct-header">
                Site Manufacturing Plants
              </div> */}
              <div id="landing-content" className="landing-content">
                <ComingSoon
                  label="Section Coming Soon..."
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default CCT;