export interface LandingStateInterface {
    label: string;
    route: string;
    authorized: boolean;
  }
export const availableApps: LandingStateInterface[] = [
    {
      label: "AOH",
      route: "aoh",
      authorized: false,
    },
    {
      label: "ABR",
      route: "abr",
      authorized: false,
    },
    {
      label: "ANC",
      route: "anc",
      authorized: false,
    },
    {
      label: "ARI",
      route: "ari",
      authorized: false,
    },
    {
      label: "AML",
      route: "aml",
      authorized: false,
    },
    {
      label: "ADL",
      route: "adl",
      authorized: false,
    },
    {
      label: "ASM",
      route: "asm",
      authorized: false,
    },
    {
      label: "ATO",
      route: "asm",
      authorized: false,
    },
  ];